import { useLocation } from 'react-router-dom'
import HeaderNavBar from './HeaderNavBar'
import LogoHeader from './LogoHeader'
import { isHideGlobalHeaderPath, isProline } from '../../../helpers'
import { useEffect } from 'react'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { Helmet } from 'react-helmet'
import {
  DEFAULT_PL_TITLE,
  DEFAULT_WS_TITLE,
} from '../../../constants/constants'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { ICustomerData, IUserProfile } from '../../../queries/user/types'

const Header = () => {
  const location = useLocation()
  const {
    setMainHeaderDivHeight,
    setMobileMainHeaderDivHeight,
    getFlashSaleBanners,
    getPageSeo,
    mainHeaderDivHeight,
    mobileMainHeaderDivHeight,
    isDownForMaintenance,
    pageSeo,
  } = useSiteContext()

  const queryClient = useQueryClient()
  const userProfile = queryClient.getQueryData(
    userKeys.userProfile(),
  ) as IUserProfile
  const customerData = queryClient.getQueryData(
    userKeys.customer(),
  ) as ICustomerData

  useEffect(() => {
    getFlashSaleBanners()
  }, [])

  useEffect(() => {
    if (location.pathname) {
      getPageSeo(location.pathname)
    }
    getFlashSaleBanners()
  }, [location.pathname])

  window.onresize = () => {
    setMainHeaderDivHeight(mainHeaderDivHeight)
    setMobileMainHeaderDivHeight(mobileMainHeaderDivHeight)
  }

  const title = isProline() ? DEFAULT_PL_TITLE : DEFAULT_WS_TITLE

  return (
    <>
      <Helmet>
        {pageSeo?.id && pageSeo?.page_title && (
          <title>
            {title} - {pageSeo?.page_title}
          </title>
        )}
        {pageSeo?.id && pageSeo?.seo_meta && (
          <meta name="description" content={pageSeo?.seo_meta} />
        )}
        <meta
          name="iadvizeCustomersId"
          id="iadvizeCustomersId"
          content={
            userProfile?.customers_id ? userProfile.customers_id.toString() : ''
          }
        />
        <meta
          name="iadvizeCustomersName"
          id="iadvizeCustomersName"
          content={
            (userProfile?.customers_firstname
              ? userProfile.customers_firstname
              : '') +
            ' ' +
            (userProfile?.customers_lastname
              ? userProfile.customers_lastname
              : '')
          }
        />
        <meta
          name="iadvizeCustomersEmailAddress"
          id="iadvizeCustomersEmailAddress"
          content={
            userProfile?.customers_email_address
              ? userProfile.customers_email_address
              : ''
          }
        />
        <meta
          name="iadvizePriceLevelsId"
          id="iadvizePriceLevelsId"
          content={
            customerData?.price_levels_id
              ? customerData.price_levels_id.toString()
              : ''
          }
        />
        <meta name="iadvizeTransactionId" content="" />
      </Helmet>
      {isHideGlobalHeaderPath(location.pathname) || isDownForMaintenance ? (
        <LogoHeader linkToHome={!isDownForMaintenance} />
      ) : (
        <HeaderNavBar />
      )}
    </>
  )
}

export default Header
